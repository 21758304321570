import {
  type Component,
  Show,
  ErrorBoundary,
  Suspense,
  createEffect,
  onMount,
} from "solid-js";
import { createAsync, revalidate, useNavigate } from "@solidjs/router";
import {
  useErrorContext,
  useSessionContext,
  useSiteContext,
} from "~/utils/contexts";
import { Icon } from "solid-heroicons";
import { heart } from "solid-heroicons/outline";
import { heart as heartSolid } from "solid-heroicons/solid";
import { getFavourites } from "~/services/favourites";
import type { FavouriteTypeOption } from "~/services/roma-api/favourites/types";
import { checkError } from "~/services/roma-api/errors";

type FavouriteHeartButtonProps = {
  sku: string;
  type: string;
};

export const FavouriteHeartButton: Component<FavouriteHeartButtonProps> = (
  props
) => {
  const { track } = useSiteContext();
  const { isPartner, toggleFav, isFavourited, clearSession } =
    useSessionContext();
  const { addError } = useErrorContext();
  const navigate = useNavigate();

  const favourites = createAsync(async () => {
    try {
      return await getFavourites();
    } catch (error) {
      const err = checkError(error);
      if (import.meta.env.DEV) {
        console.log("[getFavourites] createAsync caught error: ", err);
      }
      // TODO
      //? This is too much here, most pages have a product card so it keeps refiring :/ put in panel instead, or add a 'dont show me this again' checknox..
      // addError(err, {
      //   severity: "warning",
      //   title: "Favorites Error",
      //   message:
      //     "An error occurred while retrieving favorites - adding and deleting favorites may not function normally during your session.",
      //   suggestions: [
      //     "Please try signing out and signing back in.",
      //     "If this error persists, please contact support.",
      //   ],
      //   actions: [
      //     {
      //       label: "Sign Out",
      //       action: async () => {
      //         await clearSession();
      //       },
      //       dismissAfter: true,
      //     },
      //     {
      //       label: "Contact Support",
      //       action: async () => {
      //         navigate("/support");
      //       },
      //     },
      //   ],
      //   showDetails: true,
      // });

      throw error;
    }
  });

  const favourite = () =>
    isFavourited(favourites(), props.sku, props.type as FavouriteTypeOption);

  const handleToggleFav = async () => {
    const fav = favourites()?.get(`${props.sku}-${props.type}`)?.ID;

    try {
      await toggleFav(props.sku, props.type as FavouriteTypeOption, fav);
    } catch (error) {
      const err = checkError(error);
      if (import.meta.env.DEV) {
        console.log("[handleToggleFav] caught error: ", err);
      }
      addError(err, {
        severity: "warning",
        autoDisappear: true,
        title: "Favourites Error",
        showDetails: true,
        message: `Error ${fav ? "removing" : "adding"} item ${props.sku} ${
          fav ? "from" : "to"
        } favourites. Please try again later. If this error persists, kindly contact customer support.`,
      });
      // HERE
    }
  };
  return (
    <>
      {/* TODO - CustomErrorBoundary - Tooltip message favs unavailable..etc. Move outside to parent comps where this is composed? */}
      <ErrorBoundary
        fallback={
          <Icon
            path={heartSolid}
            class="pr-1 w-6 text-gray-200 cursor-not-allowed"
          />
        }
      >
        <Suspense
          fallback={
            <Icon
              path={heartSolid}
              class="pr-1 w-6 text-gray-300 cursor-not-allowed animate-pulse"
            />
          }
        >
          <Show when={isPartner() && favourites()}>
            <button
              class="pr-1"
              aria-label={
                favourite()
                  ? `Remove product ${props.sku} from favourites`
                  : `Add product ${props.sku} to favourites`
              }
              onClick={(evt: Event) => {
                evt.preventDefault();
                // only register tracking event if they are adding
                if (!favourite()) {
                  // TODO
                  // track(EventType.Event, {
                  //   category: "favourite",
                  //   action: "selected",
                  //   label: props.sku,
                  //   value: props.type,
                  // });
                  // trackFBQ("AddToWishlist", {
                  //   content_name: `${props.sku} - ${props.collection}, ${props.colour}, ${props.type}`,
                  // });
                }
                // toggleFav(props.sku, props.type as FavouriteTypeOption);
                handleToggleFav();
              }}
            >
              <Show
                fallback={
                  <Icon
                    path={heart}
                    class="w-6 cursor-pointer text-roma-blue"
                  />
                }
                when={favourite()}
              >
                <Icon
                  path={heartSolid}
                  class="w-6 cursor-pointer text-roma-blue"
                />
              </Show>
            </button>
          </Show>
        </Suspense>
      </ErrorBoundary>
    </>
  );
};
